import React, { useState, useEffect } from "react";
import Img from "gatsby-image";

import * as S from "./styled";

function Instructor({ 
  instructor,
 }) {
    const [classGrid, setClassGrid] = useState("");
    const [classGridSecond, setClassGridSecond] = useState("");
    const [classPadding, setClassPadding] = useState("20px 0 0 30px");
    const [instructorArray, setInstructorArray] = useState(instructor);
    
    async function handleNuberInstructor() {
      if(instructor.length === 1 && document.documentElement.scrollWidth > 700) {
        setClassGrid("1fr");
        setClassGridSecond("35% 65%");
        setClassPadding("0 0 0 20px");
      } else if (instructor.length === 2 && document.documentElement.scrollWidth > 700) {
        setClassGrid("1fr 1fr");
        setClassGridSecond("1fr");
        setClassPadding("20px 0 0 0");
      } else if (instructor.length === 3 && document.documentElement.scrollWidth > 700) {
        setClassGrid("1fr 1fr 1fr");
        setClassPadding("20px 0 0 0");
      } else {
        setClassGrid("1fr");
        setClassPadding("20px 0 0");
      }

      setInstructorArray(instructor);
    }

    useEffect(() => {
      handleNuberInstructor();
    }, []);

  return (
    <S.InstructorWrapper style={{ gridTemplateColumns: `${classGrid}`, display: instructorArray[0].name ? "grid " : "none"}}>
      {instructorArray.map(item => (
        <S.InstructorCard style={{gridTemplateColumns: `${classGridSecond}`}}>
          <div className="instructor-img">
            <Img fluid={item.image.childImageSharp.fluid} />
          </div>
          <div>
            <S.InstructorTexts className="instructor-description" style={{ padding: `${classPadding}`}}>
              <S.InstructorTitle>
                {item.name}
              </S.InstructorTitle>
              <S.InstructorDescription>
                {item.description}
              </S.InstructorDescription>
            </S.InstructorTexts>
          </div>
        </S.InstructorCard>
      ))}
    </S.InstructorWrapper>
  );
}

export default Instructor;
