import styled from 'styled-components';
import media from "styled-media-query";
 
export const CourseAboutFaqWrapper = styled.section`
    & .title_faq {
        color: "#fff";
        font-weight: 700;
        margin: 50px 0 20px;
        letter-spacing: 0; 
        font-size: 1.2rem; 

        ${media.lessThan("medium")`
            font-size: 1.1rem;
        `}
    }

    & .description_faq {
        font-weight: 500;
        letter-spacing: 0; 
        font-size: 0.8rem;

        & strong {
            color: var(--color-primary);
        }

        ${media.lessThan("medium")`
            font-size: 0.8rem;
        `}
    }
`
