import React from "react";

import * as S from "./styled";

function CourseAboutSkills(
  {
    skills
  }) {
    if(skills) {
      return (
        <S.CourseAboutSkillsWrapper>
          { skills.map( (skill) =>
            <S.CourseAboutSkillsCard>
              <span>{skill.name}</span>
              <p className="skill_description">{skill.description}</p>
            </S.CourseAboutSkillsCard>
          )}
            <S.CourseAboutSkillsCard >
              <span>Muito Mais</span>
            </S.CourseAboutSkillsCard>
        </S.CourseAboutSkillsWrapper>
      );
    } else {
      return null;
    }
}

export default CourseAboutSkills;
