import styled from 'styled-components';
import media from "styled-media-query";

export const CourseAboutPaymentWrapper = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;

    & .safe {
      border: 2px solid rgb(7, 210, 7);
    }

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;
    `}
`

export const CourseAboutPaymentCard = styled.div`
    width: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    transition: 0.3s;
    background: #1d1d1f;
    box-shadow: 0 0 0.4rem #141415;
    border-radius: 8px;
`

export const CourseAboutPaymentTitle = styled.div`
    flex: 1;

    & p {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--color-text);
        margin: 0;
    }
`

export const CourseAboutPaymentIcon = styled.div`
    flex: 1;
    text-align: right;

    & img {
        width: 50%;
    }
`

