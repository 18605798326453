import React from "react";

import { CameraVideo } from "@styled-icons/bootstrap/CameraVideo";
import { Download } from "@styled-icons/boxicons-solid/Download";
import { Stopwatch } from "@styled-icons/entypo/Stopwatch";
import { MobileAlt } from "@styled-icons/boxicons-regular/MobileAlt";
import { Certificate } from "@styled-icons/fluentui-system-regular/Certificate";
import { Message } from "@styled-icons/boxicons-regular/Message";

import * as S from "./styled";

const CourseAboutInformation = () => {
  return (
    <S.CourseAboutInformationWrapper>
      <div className="items">
        <div className="item">
          <div className="item-icon">
            <CameraVideo />
          </div>
          <span>Áudio e Vídeo em Alta Definição</span>
        </div>
        <div className="item">
          <div className="item-icon">
            <Certificate />
          </div>
          <span>Certificado de Conclusão</span>
        </div>
        <div className="item">
          <div className="item-icon">
            <Message />
          </div>
          <span>Suporte Dedicado</span>
        </div>
      </div>
      <div className="items">
        <div className="item">
          <div className="item-icon">
            <Stopwatch />
          </div>
          <span>Sem prazo de expiração</span>
        </div>
        <div className="item">
          <div className="item-icon">
            <Download />
          </div>
          <span>Recursos para download</span>
        </div>
        <div className="item">
          <div className="item-icon">
            <MobileAlt />
          </div>
          <span>Acesso Mobile</span>
        </div>
      </div>
    </S.CourseAboutInformationWrapper>
  );
}

export default CourseAboutInformation;
