import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import CourseAboutVideo from '../components/CourseAboutVideo';
import CourseAboutInformation from '../components/CourseAboutInformation';
import CourseAboutDescription from '../components/CourseAboutDescription';
import CourseAboutPayment from '../components/CourseAboutPayment';
//import CourseAboutGarantia from '../components/CourseAboutGarantia';
import CourseAboutInstructor from '../components/CourseAboutInstructor';
import CourseAboutComments from '../components/CourseAboutComments';
import CourseAboutFaq from '../components/CourseAboutFaq';
import CourseAboutCardLateral from '../components/CourseAboutCardLateral';
import Seo from 'components/seo';

import * as S from "../components/CourseAbout/styled";

export default function Course({ data }) {
  const {
    title,
    image,
    slug,
    video,
    introduction,
    description,
    price,
    link_compra,
    author,
    class_course,
    skills,
    instructor,
    comments,
  } = data.markdownRemark.frontmatter;
  return (
    <Layout title="Curso">
      <Seo title="Descrição detalhada sobre os cursos" postNode={data.markdownRemark} postPath={slug} postSEO/>
      <S.CourseAboutWrapper>
        <S.CourseAboutMain>
        <CourseAboutVideo 
          video={video}
        />
        <h1 style={{ marginTop: 5 }}>Informação</h1>
          <CourseAboutInformation />
        <h1>Descrição do curso</h1>
          <CourseAboutDescription
            description={description}
            skills={skills}
          />
        <h1>Formas de pagamento</h1>
          <CourseAboutPayment />
          {/*<CourseAboutGarantia />*/}
        <h1 style={{display: instructor[0].name ? "flex" : "none"}}>Instrutor</h1>
          <CourseAboutInstructor
            instructor={instructor}
          />
        <h1 style={{display: comments ? "block" : "none"}}>Depoimentos</h1>
        <CourseAboutComments
          comments={comments}
        />
        <h1>FAQ</h1>
        <CourseAboutFaq />
        </S.CourseAboutMain>
        <S.CourseAboutCardPriceInfo>
          <CourseAboutCardLateral
            title={title}
            image={image.childImageSharp.fluid}
            price={price}
            link_compra={link_compra}
            introduction={introduction}
            author={author}
            class_course={class_course}
          />
        </S.CourseAboutCardPriceInfo>
      </S.CourseAboutWrapper>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      frontmatter {
        title
        slug
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        video
        introduction
        description
        price {
          price_total
          price_part
          number_part
        }
        link_compra
        author
        class_course
        instructor {
          name
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
        skills {
          name
          description
        }
        comments {
          name
          description
          video
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

Course.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        category: PropTypes.shape({
          frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
};
