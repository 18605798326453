import styled from 'styled-components';
import media from "styled-media-query";

export const CourseAboutCardLateral = styled.article`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #444;

    & .flex-col {
      flex-direction: column;
    }

    & .flex {
      display: grid;
    }

    & .justify-between {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      & h2 {
        font-size: 0.9rem;
        font-weight: 700;
        margin: 0;
        letter-spacing: 0px;
      }

      & p {
        font-size: 0.7rem;
        font-weight: 400;
        margin: 10px 0 0;
        line-height: 1rem;
      }
    }

    & .mt-16 {
        margin-top: 10px;
    }              

    & select {
        width: 100%;
        height: 46px;
        border: 2px solid #444;
        border-radius: 4px;
        color: #fff;
        background: var(--color-background-second);
        padding: 10px;
        font-size: 0.8rem;

        ${media.lessThan("medium")`
          height: 56px;
        `}
    }
`

export const CourseAboutCardLateralImage = styled.div`
    width: 100%;
    padding: 0;
    margin-bottom: 10px;

    & img {
      width: 100%;
      border-radius: 4px;
    }
`

export const CourseAboutCardLateralPrice = styled.div`
  display: grid;

  & p {
    font-size: 0.8rem;
    color: var(--color-text);
    margin: 20px 0 0;

    & strong {
      color: rgb(7, 210, 7);
    }
  }

  & span {
    font-size: 0.8rem;
    margin: 0;
    font-weight: 500;
    color: #fff;

    & strong {
      font-size: 2rem;
      color: rgb(7, 210, 7);
    }
  }
`

export const CourseAboutCardLateralButton = styled.a`
    width: 100%;
    height: 46px;
    border: none;
    border-radius: 4px;
    color: var(--color-background-second);
    background: rgb(7, 210, 7);
    font-weight: 700;
    margin: 10px 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    text-decoration: none;
    font-size: 0.8rem;
    transition: filter 0.3s;

    ${media.lessThan("medium")`
      height: 56px;
    `}

    &:hover {
      background: rgb(7, 180, 7);
    }
`

export const CourseGroupDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CourseGroupSubTitle = styled.h3`
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: bold;
  color: var(--color-text);
  margin: 5px 0;
`

export const CourseGroupSubDescription = styled.p`
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--color-text-light);
  margin: 5px 0;
  display: flex;
  align-items: center;

  & img {
    width: 0.8rem;
    margin-right: 2px;
  }
`