import styled from 'styled-components';
import media from "styled-media-query";

export const CourseAboutCommentsWrapper = styled.section`
`

export const CourseAboutComment = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    & .comment {
        & .comment-icon {
            width: 40px;
            position: relative;
            
        & img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
}
    }

    & .comment-info {
        width: 100%;
        margin: 0 10px;
    }

     ${media.lessThan("medium")`
        width: 100%;
        grid-template-columns: 1fr;
    `}
`

export const CourseAboutCommentTitle = styled.h3`
    font-size: 1.1rem;
    margin: 5px 0;
`

export const CourseAboutCommentSubTitle = styled.span`
    font-size: 0.8rem;
    line-height: 24px;
    font-weight: 400;
    color: #999;
`

export const CourseAboutCommentDescription = styled.p`
    margin: 0 0 20px;
    font-size: 0.8rem;
    line-height: 24px;
    font-weight: 400;
`