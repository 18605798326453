import styled from 'styled-components';
import media from "styled-media-query";
 
export const CourseAboutInformationWrapper = styled.section`
    display: flex;
    justify-content: space-between;
    gap: 80px;

    & .items {
        flex-direction: column;
        display: flex;
        flex: 1
    }

    & .item {
        margin-bottom: 16px;
        align-items: center;
        display: flex;

        ${media.lessThan("medium")`
            margin-bottom: 26px;
        `}

        & span {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
        }
    }

    & .item-icon {
        color: var(--color-text);
        margin-right: 12px;
        justify-content: left;
        display: flex;
        width: 1.4rem;
    }

    ${media.lessThan("medium")`
        display: grid;
        justify-content: left;
   `}
`