import React from "react";

import CourseAboutSkills from '../CourseAboutSkills';

import * as S from "./styled";

const CourseAboutDescription = (
  {
    description,
    skills
  }) => {
  return (
    <S.CourseAboutDescriptionWrapper>
        <p>{description}</p>

        <p style={{color: "#fff", fontWeight: 500, marginTop: 50, letterSpacing: 0, fontSize: '20px', marginBottom: 50}}>
          O que ira aprender no decorrer do curso:
        </p>

        <CourseAboutSkills
          skills={skills}
        />

      </S.CourseAboutDescriptionWrapper>
    );
}


export default CourseAboutDescription;
