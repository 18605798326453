import React from "react";

import * as S from "./styled";

const CourseAboutFaq = () => {
  return (
    <S.CourseAboutFaqWrapper>
      <h3 className="title_faq">
        Após a compra do curso tenho prazo pra poder assistir?
      </h3>
      <span className="description_faq">Não, o curso pode ser assistido a qualquer tempo sem limite de prazo.</span>

      <h3 className="title_faq">
        O curso oferece certificado?
      </h3>
      <span className="description_faq">Sim, ao final do curso você receberá um certificado baseado na carga horária pra concluir o curso</span>

      <h3 className="title_faq">
        Existe suporte para as minhas dúvidas?
      </h3>
      <span className="description_faq">Sim, temos um time de suporte pronto pra tirar as dúvidas relativas ao conteúdo do curso.</span>

      <h3 className="title_faq">
        Como funciona o “Prazo de Garantia”?
      </h3>
      <span className="description_faq">O Prazo de Garantia é o período que você tem para pedir o reembolso integral do valor pago pela sua compra, caso o produto não seja satisfatório.
Assim que solicitado, seu reembolso é processado automaticamente pela Hotmart em até 5 dias. Para pagamentos com boleto bancário, você precisa preencher uma conta bancária para receber o dinheiro. Passados os 5 dias, o valor poderá ser identificado em sua conta em até 7 dias úteis. Já o estorno na fatura do cartão de crédito varia de acordo com o meio de pagamento e pode demorar de 30 a 60 dias.</span>

      <h3 className="title_faq">
        Posso acessar o curso de qualquer lugar?
      </h3>
      <span className="description_faq">Sim! A plataforma de ensino a distância é compátivel com todos os dispositivos: Celular, Tablet, Notebook, Desktop etc..</span>
      <h3 className="title_faq">
        Qual o formato do curso?
      </h3>
      <span className="description_faq">O curso é 100% em videoaulas através da plataforma de ensino a distância. Uma plataforma completa, onde você tem total controle do seu aprendizado onde e quando quiser.</span>
    </S.CourseAboutFaqWrapper>
      );
}


export default CourseAboutFaq;
