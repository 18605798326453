import styled from 'styled-components';
import media from "styled-media-query";

export const InstructorWrapper = styled.section`
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    display: grid;
    gap: 15px;

    ${media.lessThan("medium")`
      margin: 50px auto;
      flex-direction: column-reverse;
      justify-content: center;
      height: auto;
   `}
`

export const InstructorCard = styled.div`
  justify-content: center;
  align-items: flex-start;
  display: grid;
  margin: 0;
  padding: 20px;
  transition: 0.3s;
  border-radius: 8px;
  position: relative;

  & .instructor-img {
    text-align: right;
    height: 100%;
    & img {
      width: 100%;
      border-radius: 8px;
      ${media.lessThan("medium")`
        width: 100%;
     `}
    }
  }

  & .instructor-description {
    text-align: left;
    display: grid;
    justify-content: center;
    align-items: center;
  }
`

export const InstructorTexts = styled.div`
    width: 100%;

    ${media.lessThan("medium")`
      width: auto;
    `}
`

export const InstructorTitle = styled.h1`
    color: #FFF;
    font-size: 1.4rem !important;
    margin: 0 0 10px !important;
    
    ${media.lessThan("medium")`
      font-size: 1.2rem;
    `}
`

export const InstructorDescription = styled.p`
    font-weight: 400;
    font-size: 0.8rem;
    margin: 0;
`















