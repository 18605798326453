import styled from 'styled-components';
import media from "styled-media-query";
 
export const CourseAboutSkillsWrapper = styled.section`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;
   `}
`

export const CourseAboutSkillsCard = styled.div`
    justify-content: left;
    align-items: flex-start;
    border: 1px solid var(--color-primary-light);
    box-shadow: 0 0 0.2rem var(--color-primary-light);
    width: 100%;
    padding: 20px;
    background: #1d1d1f;
    border-radius: 8px;
    position: relative;
    transition: 0.2s;

    & span {
        color: #fff;
        font-size: 0.9rem;
        font-weight: 500;
    }

    & .skill_description {
        display: none;
        font-size: 0.7rem;
        margin: 10px 0 0;
    }
`