import React from "react";
import Img from "gatsby-image";

import PaymentImg from '../../images/payment.jpg'

import * as S from "./styled";

const CourseAboutCardLateral = ({ title, image, introduction, price, class_course, author, link_compra }) => {
    /*const [priceSelected, setPriceSelected] = useState("");
    const [linkCurrent, setLinkCurrent] = useState("");

    const listPrices = [
        { id: 1, name: "Selecione um valor" },
        { id: 2, name: price_total },
        { id: 3, name: price_portion }
    ];

    function handleSelectedPrice () {
        if (priceSelected === price_total) {
            setLinkCurrent(link_total);
        } else if (priceSelected === price_portion) {
            setLinkCurrent(link_portion);
        } else {
            alert("Favor selecione um valor!");
        }


    }
    */

    return (
        <S.CourseAboutCardLateral>
            <div className="flex-col">
                <S.CourseAboutCardLateralImage>
                    <Img fluid={image}/>
                </S.CourseAboutCardLateralImage>
                <div className="flex justify-between">
                    <h2>{title}</h2>
                    <p>{introduction}</p>
                </div>
                <S.CourseGroupDetails>
                  <div>
                    <S.CourseGroupSubTitle>Qtd de aulas</S.CourseGroupSubTitle>
                    <S.CourseGroupSubDescription>{class_course}</S.CourseGroupSubDescription>
                  </div>
                  <div>
                    <S.CourseGroupSubTitle>Autor</S.CourseGroupSubTitle>
                    <S.CourseGroupSubDescription>{author}</S.CourseGroupSubDescription>
                  </div>
                </S.CourseGroupDetails>
                <S.CourseAboutCardLateralPrice>
                  <p>Preço à vista de <strong>R${price[0].price_total}</strong> ou</p>
                  <span>em até {price[0].number_part}{" "}<strong>R${price[0].price_part}</strong></span>
                  {/*<span>3x de <strong>{price_portion}</strong></span>*/}
                </S.CourseAboutCardLateralPrice>
                {/*
                  <div className="mt-16">
                    <select
                      value={priceSelected}
                      onChange={(e) => setPriceSelected(e.target.value)}
                    >
                      {listPrices.map((item, index) => (
                        <option value={item.name} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                */}

                <S.CourseAboutCardLateralButton
                  href={link_compra}
                  className="btn"
                >
                  COMPRAR AGORA!
                </S.CourseAboutCardLateralButton>
                <img src={PaymentImg} />
            </div>
        </S.CourseAboutCardLateral>                                                                                                                                                                            
    )
}

export default CourseAboutCardLateral;




























