import React from "react";
import ReactPlayer from "react-player";

import * as S from "./styled";

const CourseAboutVideo = ({ video }) => {
    return (
            <S.CourseAboutVideoWrapper style={{ display: video ? "grid" : "none" }}>
                <S.PlayerWrapper>
                    <ReactPlayer
                        url={`${video}`}
                        className="react-player"
                        controls="true"
                        config={{
                        youtube: {
                            playerVars: { showinfo: 1 }
                        }
                        //   youtube: {
                        //     playerVars: {
                        //       start: this.state.startSecond,
                        //       end: this.state.endSecond,
                        //     }
                        //   }
                        }}
                        // playbackRate={this.state.playbackRate}
                        playing={false}
                        width='100%'
                        height='100%'
                    />
                </S.PlayerWrapper>                                                                                                                                                                                         
            </S.CourseAboutVideoWrapper>
    )
}

export default CourseAboutVideo;