import styled from 'styled-components';
import media from "styled-media-query";

export const CourseAboutVideoWrapper = styled.div`
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 50px;

    & .card_lateral {
        display: none;

        ${media.lessThan("medium")`
            display: block;
        `}
    }

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;
        padding-top: 20px;
    `}
`

export const PlayerWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

    & .react-player {
        position: absolute;
        top: 0;
        left: 0;
        background: #000000;
        border-radius: 8px;
    }
`