import React from "react";

import boleto from "../../images/course-about/boleto.png";
import card from "../../images/course-about/card.png";
import checked from "../../images/course-about/checked.png";

import * as S from "./styled";

const CourseAboutPayment = () => {
  return (
      <S.CourseAboutPaymentWrapper>
          <S.CourseAboutPaymentCard>
            <S.CourseAboutPaymentTitle>
              <p>Boleto</p>
            </S.CourseAboutPaymentTitle>
            <S.CourseAboutPaymentIcon>
              <img src={boleto} alt="boleto" />
            </S.CourseAboutPaymentIcon>
          </S.CourseAboutPaymentCard>
          <S.CourseAboutPaymentCard className="safe">
            <S.CourseAboutPaymentTitle>
              <p>
                Totalmente <strong style={{ color: "rgb(7, 210, 7)" }}>seguro</strong>
              </p>
            </S.CourseAboutPaymentTitle>
            <S.CourseAboutPaymentIcon>
              <img src={checked} alt="credit" />
            </S.CourseAboutPaymentIcon>
          </S.CourseAboutPaymentCard>
          <S.CourseAboutPaymentCard>
            <S.CourseAboutPaymentTitle>
              <p>Crédito ou Débito</p>
            </S.CourseAboutPaymentTitle>
            <S.CourseAboutPaymentIcon>
              <img src={card} alt="credit" />
            </S.CourseAboutPaymentIcon>
          </S.CourseAboutPaymentCard>
      </S.CourseAboutPaymentWrapper>
    );
}

export default CourseAboutPayment;
