import React from "react";
import Img from "gatsby-image";

import Video from "../CourseAboutVideo";

import * as S from "./styled";

const CourseAboutComments = (
  {
    comments
  }
) => {
  return (
      <S.CourseAboutCommentsWrapper>
        <S.CourseAboutComment>
          { comments.map( (comment) =>
            <div className="comment" style={{ display: comment ? "flex" : "none" }}>
              <div className="comment-icon">
                <Img fluid={comment.image.childImageSharp.fluid} />
              </div>
              <div className="comment-info">
                <S.CourseAboutCommentTitle>{comment.name}</S.CourseAboutCommentTitle>
                <S.CourseAboutCommentSubTitle>Aluno(a)</S.CourseAboutCommentSubTitle>
                <S.CourseAboutCommentDescription style={{ display: comment.description ? "flex" : "none" }}>
                  {comment.description}
                </S.CourseAboutCommentDescription>
                <S.CourseAboutCommentDescription style={{ display: comment.video ? "flex" : "none" }}>
                  <Video
                    video={comment.video}
                  />
                </S.CourseAboutCommentDescription>
              </div>
            </div>
          )}
        </S.CourseAboutComment>
      </S.CourseAboutCommentsWrapper>
    );
}

export default CourseAboutComments;
