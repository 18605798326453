import styled from 'styled-components';
import media from "styled-media-query";
 
export const CourseAboutWrapper = styled.section`
    width: 100%;
    max-width: 1150px;
    padding: 60px 20px 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 70% 27%;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    & h1 {
        color: var(--color-text);
        font-size: 2rem;
        margin: 100px 0 50px;
    }

    @media (max-width: 900px) {
    /* screen width is less than 768px (medium) */
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse;
    }

    ${media.lessThan("medium")`
        width: auto;
        padding: 20px 20px 0;
   `}
`

export const CourseAboutMain = styled.main`
    position: relative;

    ${media.lessThan("medium")`
        margin-top: 20px;
    `}
`

export const CourseAboutCardPriceInfo = styled.div`
    border-radius: 8px;
    height: auto;
    position: sticky;
    top: 10px;
    bottom: 1rem;
    z-index: 999;
    box-shadow: 0 0 0.4rem #141415;
    background: var(--color-background-second);

    @media (max-width: 900px) {
        position: relative;
    }
`