import styled from 'styled-components';
import media from "styled-media-query";
 
export const CourseAboutDescriptionWrapper = styled.section`
    & p {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 300;
        margin: 0 0 20px;
      }

    ${media.lessThan("medium")`
        width: auto;
        margin: 0;
   `}
`